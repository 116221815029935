@import '../kendo-theme/tokens';

@import "@progress/kendo-theme-default/dist/all";

@import '../kendo-theme/overrides';

// Pager Style
.k-grid-pager {
  background-color: white !important;

  &:focus {
    box-shadow: none !important;
  }

  &:not(.k-pager-sm) {
    .k-dropdownlist.k-pager-nav {
      display: none;
    }
  }


  .k-pager-numbers-wrap {

    .k-pager-numbers {
      gap: 5px;

      .k-pager-nav {
        border-radius: 100px;

        &:focus {
          box-shadow: none;
        }

        &.k-selected, &:hover {
          color: #394085 !important;
          background-color: rgba(57, 64, 133, 0.2) !important;
        }
      }
    }
  }
}

.k-pager-refresh:hover,
.k-pager-nav.k-link:hover,
.k-state-hover.k-pager-refresh,
.k-state-hover.k-pager-nav.k-link {
  border-radius: 100px;
}
