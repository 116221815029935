:host ::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.columnpadding {
  padding-left: 0px !important;
}

.rowdata {
  margin-right: 0px !important;
  margin-left: 0px !important;
}


/* Color Code Start */

.lightgrey {
  color: #7A7A7A;
}

.blucolor {
  color: #191E57;
}

.lightcolor {
  color: #1C1B1B;
}

.grey {
  color: #A6A6A6;
}

.black {
  color: #000000;
}

.lightsgrey {
  color: #939393;
}

.custobutton {
  position: absolute !important;
  right: 1px;
}

.darkblue {
  color: #394085 !important;
}

.dark-grey {
  color: #383838;
}

.white {
  color: #FAFAFA;
}

.light-purple-bg {
  background-color: #DBDEFF
}

.purple-bg {
  background-color: #B195DB;
}

.light-black {
  color: #575757 !important;
}

.dblack {
  color: #1C1C1C !important;
}

.light-blue {
  color: #00AEEF;
}


/* Color Code End */


/* Checkbox Start */

.checkboxitem {
  background: #FFFFFF;
  border-radius: 3px;
  margin-left: 13px;
}


/* Checkbox End */

.deletebutton-wrapper {
  width: 90px;
  height: 42px;
}

.deletebutton button {
  background: #D41515 !important;
  box-shadow: 0px 2px #394085 !important;
  width: 90px;
  color: #ffffff;
  border: 1px solid #394085;
}


/* .mat-raised-button {
  box-shadow: 0px 2px #B195DB !important;
  padding: 0px 24px !important;
}

.mat-stroked-button:not([disabled]) {
  border-color: #394085;
} */


/* .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
    color: #575757;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 0px 0px 2px #f3f2f4 !important;
} */

.normalhelfont {
  font-family: HelveticaNeueRegular, Helvetica, Arial, sans-serif;
  font-style: normal;
}

.bold_18px_font,
.normal_16px_39px_font,
.normal_24px_44px {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
}

.normal_12px_font,
.normal_12px_18px_font {
  font-family: HelveticaNeueRegular, Helvetica, Arial, sans-serif;
}

.normal_16px_font,
.normal_16px_19px_font,
.normal_18px_font,
.normal_16px_22px_font,
.normal_12px_44px_font,
.normal_14px_22px_font,
.normal_22px_14px_font,
.normal_14px_16px_font,
.normal_10px_44px_font,
.normal_13px_15px_font,
.normal_20px_22px_font,
.normal_12px_22px_font,
.normal_16px_44px_font,
.normal_12px_14px_font,
.normal_9px_11px_font,
.normal_14px_44px_font {
  font-weight: 400;
}

.normal_16px_39px_font,
.normal_11px_font,
.normal_12px_font,
.normal_12px_18px_font,
.normal_24px_44px {
  font-weight: 500;
}

.bold_12px_font,
.bold_16px_font,
.bold_18px_font,
.bold_20px_font,
.bold_20px_26px_font,
.bold_14px_16px_font,
.bold_22px_14px,
.bold_22px_24px,
.bold_21px_20px {
  font-weight: 700;
}

.normal_16px_22px_font,
.bold_18px_font,
.normal_14px_22px_font,
.normal_12px_22px_font {
  line-height: 22px;
}

.bold_20px_font,
.normal_12px_44px_font,
.normal_10px_44px_font,
.normal_16px_44px_font {
  line-height: 44px;
}

.normal_16px_19px_font {
  font-size: 16px;
  line-height: 19px;
}

.normal_16px_font {
  font-size: 16px;
  line-height: 19px;
}

.normal_9px_11px_font {
  font-size: 9px;
  line-height: 11px;
}

.normal_14px_44px_font {
  font-size: 14px;
  line-height: 44px;
}

.bold_22px_14px {
  font-size: 22px;
  line-height: 14px;
}

.normal_24px_44px {
  font-size: 24px;
  line-height: 44px;
}

.bold_22px_24px {
  font-size: 22px;
  line-height: 24px;
}

.bold_21px_20px {
  font-size: 21px;
  line-height: 20px;
}

.normal_16px_22px_font {
  font-size: 16px;
  letter-spacing: 0.15px;
}

.normal_16px_44px_font {
  font-size: 16px;
}

.normal_12px_14px_font {
  font-size: 12px;
  line-height: 14px;
}

.normal_16px_39px_font {
  font-size: 16px;
  line-height: 39px;
}

.normal_11px_font {
  font-size: 11px;
  line-height: 13px;
}

.normal_18px_font {
  font-size: 18px !important;
  line-height: 14px;
}

.bold_12px_font {
  font-size: 12px;
}

.bold_16px_font {
  font-size: 16px;
}

.bold_18px_font {
  font-size: 18px;
}

.bold_20px_font {
  font-size: 20px;
}

.normal_12px_font {
  font-size: 12px;
  line-height: 20px;
}

.normal_12px_22px_font {
  font-size: 12px;
}

.normal_12px_44px_font {
  font-size: 12px;
}

.normal_14px_22px_font {
  font-size: 14px;
}

.normal_22px_14px_font {
  font-size: 22px;
  line-height: 14px;
}

.bold_20px_26px_font {
  font-size: 20px;
  line-height: 26x;
}

.bold_14px_16px_font {
  font-size: 14px;
  line-height: 16px;
}

.normal_14px_16px_font {
  font-size: 14px;
  line-height: 16px;
}

.normal_10px_font {
  font-size: 10px;
}

.normal_10px_44px_font {
  font-size: 10px;
}

.normal_13px_15px_font {
  font-size: 13px;
  line-height: 15px;
}

.normal_12px_18px_font {
  font-size: 12px;
  line-height: 18px;
}

.normal_20px_22px_font {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.15px;
}

.medium_18px_font {
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
}

.letterspacing_15 {
  letter-spacing: 0.15px;
}

.emodaltitle {
  color: #000000 !important;
}

.k-window {
  background-color: #FAFAFA;
}

.bottom-bar-wrapper {
  display: flex;
  /* margin-bottom: 5px; */
}

.borderbox {
  border: 1px solid #979797;
  background: #FFFFFF;
}

::ng-deep .deletepopup {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.uppercasetext {
  text-transform: uppercase;
}

.normal_12px_font,
.normal_12px_18px_font,
.italicfont {
  font-style: italic;
}

.tabcontent {
  display: block;
  padding: 6px 0px;
  border-top: none;
}

.iconcolor {
  left: 39%;
  color: #575757;
}

::ng-deep input.mat-input-element {
  margin-top: -0.300em !important;
}

.rowdatapaddingl {
  padding-left: 8px;
}

.paddingtop {
  padding-top: 12px;
}

.paddingtops {
  padding-top: 20px;
}

.paddingbottoms {
  padding-bottom: 20px;
}

.paddingspace {
  padding-bottom: 20px;
}


/* Laptop Screen Start */

@media (max-width: 1440px) {
  .k-dialog-wrapper .k-dialog {
    margin-left: 20%;
  }
  .normal_12px_font {
    font-size: 10px !important;
    line-height: 20px;
  }
  .tab button {
    font-size: 15px !important;
  }
  .bold_20px_font {
    font-size: 16px !important;
  }
  .generaltemplate .col-xl-4 {
    max-width: 31.333333%;
  }
  .templateName {
    padding: 0px !important;
  }
}

@media (max-width: 1280px) {
  .bold_20px_font {
    font-size: 14px !important;
  }
  .templateName {
    padding: 0px !important;
  }
}


/* Laptop Screen End */

.textunderline {
  text-decoration-line: underline;
}

.save-button-wrapper {
  height: 41px;
  width: 77px;
}

.mat-ink-bar {
  height: 5px !important;
}

.k-grid-header .k-header > .k-link {
  font-size: 20px !important;
  font-weight: bold !important;
  color: black !important;
}

.mat-tab-label .mat-tab-label-content {
  color: black;
}

.mat-field-table {

  .mat-form-field-wrapper {
    background: transparent;
    padding: 0;
    margin: 0;
  }

  .mat-form-field-flex {
    padding: 0 !important;
    min-height: 39px;
    height: 39px;
  }

  .mat-form-field-outline {
    background: transparent;
    color: transparent;
  }

  .mat-form-field-outline-thick {
    opacity: 0 !important;
  }

  .mat-form-field-infix {
    margin: 0;
    padding: 10px 0 !important;
  }

  &.visible-border {
    .mat-form-field-outline {
      color: initial;
    }

    .mat-form-field-outline-thick {
      opacity: initial !important;
    }

    .mat-form-field-infix {
      margin: initial;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline-end, .mat-form-field-outline-start {
      border: none !important;
    }
  }
}

.right-popup-anchor {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
}

.right-align-popup {
  background: #ffffff;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 11111;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

  .header-container {
    height: 52px;
    min-height: 52px;
    background: #394085;
    padding-left: 19px;
    padding-right: 15px;
  }

  .header-container .title-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  .header-container .ic-trash {
    font-size: 18px;
    cursor: pointer;
  }

  .header-container .ic-close {
    font-size: 22px;
    margin-left: 20px;
    height: unset;
    width: unset;
    cursor: pointer;
  }
}

.right-align-popup .k-popup {
  background: none;
  border-radius: 0;
  border: none;
  height: 100%;
}

// Remove Heading Tag Bottom Margin in Kendo grid
.k-grid-table {
  tbody {
    tr {
      height: 35px;

      td {
        h1, h2, h3, h4, h5, h6 {
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.default-text {
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  color: #00AEEF;
  text-decoration-line: underline !important;
}

.disabled{
  opacity: 0.5;
  pointer-events: none;
}

.white-space-normal{
  white-space: normal;
}

.pointer-events-none{
  pointer-events: none;
}

.pointer-events-all{
  pointer-events: all;
}

div[kendowatermarkoverlay] {
  display: none;
}
