/*@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600;700&display=swap');*/
@import "../src/styles/scheduler-navigator-calendar";

body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeueRegular" !important;
  line-height: 1.35715 !important;
  color: #333 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

@font-face {
  font-family: 'HelveticaNeueRegular';
  src: url('fonts/Helvetica400.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueBold';
  src: url('fonts/HelveticaNeuBold.ttf');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('fonts/HelveticaNeueMedium.ttf');
  font-weight: medium;
  font-style: medium;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.helvetica-neue-regular {
  font-family: "HelveticaNeueRegular", sans-serif;
}

.helvetica-neue-bold {
  font-family: "HelveticaNeueBold", sans-serif;
}

.helvetica-neue-medium {
  font-family: "HelveticaNeueMedium", Sans-serif;
}

.quicksand {
  font-family: Quicksand, sans-serif;
}

#heading {
  line-height: 44px;
  font-size: 28px;
  color: #394085 !important;
}

.mat-tab-label,
.k-grid td,
.mat-expansion-panel-content {
  font-size: 1em;
  line-height: 22px;
}

a {
  color: #00AEEF !important;
}

a:hover {
  color: #394085;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quicksand', sans-serif;
  color: #394085;
}

button:focus {
  outline: none !important;
}

.bold {
  font-weight: bold;
}

.k-form-field.full-width,
.mat-form-field.full-width {
  width: 100%;
}

.mat-form-field.half-width {
  width: 50%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #191e57;
  color: white;
}

.footer-margin {
  margin-bottom: 120px;
}

.mat-icon {
  margin-right: 0.5rem;
}

.k-child-animation-container {
  height: 100%;
}

.kavanii-menu {
  margin-top: 10px;
  min-width: 260px !important;
}

.k-input-value-text::before {
  content: none;
}

.mat-expansion-indicator::after {
  color: #00AEEF !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.main-content {
  margin-left: 245px;
}

.control-row {
  position: static;
  bottom: 0;
  /* height: 80px; */
  width: 100%;
}

.mat-form-field-outline {
  background-color: white;
}


/* .mat-button-base {
    height: 42px;
} */

.mat-drawer-container {
  color: black !important;
}

.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}

.big .mat-checkbox-label {
  line-height: 42px !important;
  padding-left: 1rem;
  font-family: "HelveticaNeueMedium" !important;
}

.mat-checkbox.wrap-label .mat-checkbox-label {
  white-space: break-spaces;
  line-height: initial;
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.bg-white {
  background-color: white;
}

.input-icon {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
}

.default-icon {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  margin-right: 10px;
  margin-left: 5px;
}

.header-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}

.large-header-icon {
  width: 34px !important;
  height: 34px !important;
  font-size: 34px !important;
}

.title-icon {
  width: 46px !important;
  height: 46px !important;
  font-size: 46px !important;
}

.blue-icon {
  color: #00AEEF;
}

.yellow-icon {
  color: #FFCC32;
}

.dark-blue-icon {
  color: #191e57;
}

.purple-icon {
  color: #A18BD6;
}

.reverse-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.mat-stroked-button[color='primary'] {
  border-bottom: 3px solid #191e57;
}

.mat-stroked-button[color='accent'] {
  border-bottom: 3px solid #394085;
}

.mat-stroked-button[color='warn'] {
  border-bottom: 3px solid #a18bd6;
}

.mat-stroked-button.no-underline {
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-stroked-button[color='primary'].matchBorderColor {
  border-color: #191e57;
}

.mat-stroked-button[color='accent'].matchBorderColor {
  border-color: #394085;
}

.mat-stroked-button[color='warn'].matchBorderColor {
  border-color: #A18BD6;
}

.mat-button-toggle-checked {
  background-color: #A18BD6;
  color: white !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}
.k-table-thead, .k-table-header, .k-table-group-sticky-header {
  background-color: white !important;
}

.k-pager-wrap,
.k-grid-header,
.k-grid tr.k-table-alt-row {
  background-color: white !important;
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover, .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 11%, transparent) !important;
}

col.k-sorted, .k-table-th.k-sorted {
  background-color: white;
}

//.k-grid {
//  .k-table-td, .k-table-th {
//    border: 0;
//  }
//}

.k-grid th {
  font-weight: bold;
}

//.k-pager-wrap,
//.k-grid-header,
//.k-grid {
//  .k-table-td {
//    border-bottom-width: 1px !important;
//  }
//}

.k-grid {

  .k-grid-header {

    .k-grid-header-wrap {
      border-right: 0;
    }

    .k-i-sort-asc-small {
      color: #394085;
    }

    .k-i-sort-desc-small {
      color: #394085;
    }

    th {
      //padding: 10px 5px !important;
    }
  }

  th, td {
    font-size: 15px;
    border-bottom-width: 1px !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  td {
    font-size: 14px !important;
    padding: 2.5px 5px !important;
  }

  .k-table-th {
    font-family: "HelveticaNeueBold", sans-serif;
  }

  .k-grouping-drop-container {
    color: #ABAAB0;
    font-family: "HelveticaNeueBold", sans-serif;
  }
}

.k-grid.border {

  .k-grid-header th {
    border-bottom-width: 0 !important;
    min-height: 40px;
  }

  th, td {
    border-right-width: 1px;
    border-bottom-width: 1px;

    &:last-child {
      border-right-width: 0;
    }
  }
}

.mat-select-panel {
  max-width: 100%;
}


/* .mat-tab-label-active {
    font-weight: bold;
} */

.mat-expansion-panel-header-description {
  direction: rtl;
}

span.required {
  color: rgba(0, 0, 0, 0.54);
}

.grey-rounded-border {
  border: 1px solid #eee;
  border-radius: 15px;
  background: white;
  box-shadow: 3px 3px #ededed73;
  height: 100%;
}

.settingmenu {
  color: #202CCB;
  text-decoration: underline;
}

.vertical .mat-tab-header {
  min-width: 250px;
}

span.color {
  height: 15px;
  width: 15px;
  border: #ddd 1px solid;
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
  color: #c83427 !important;
  margin-top: 0.6666666667em !important;
  font-weight: 500 !important;
  line-height: 1.125 !important;
  font-family: "HelveticaNeueRegular", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
}

.mat-expansion-panel-header-title {
  font-weight: bold;
}

.mat-expansion-panel-header {
  border-bottom: 1px solid #eee;
  border-radius: 0;
  margin-bottom: 2rem;
}

.black {
  color: black;
}

.mat-select-arrow {
  color: #00AEEF !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 6px solid !important;
}

.k-switch[aria-readonly=true] {
  opacity: 0.5;
}

.hazel-text {
  color: #dc3545;
}

.grey-icon {
  color: #ccc;
}

.lavander-icon {
  color: #A18BD6;
}

.kendo-dropdown-bottom {
  margin-bottom: 12px;
}


/* Override Kendo dropdown style start */


/* Override Kendo dropdown style end */


/*
  input.k-textbox {
    height: 52px;
  } */

.delete-left {
  float: left;
  margin-left: 3rem !important;
}

.kendo-dropdown-label {
  font-size: 15px;
  color: gray;
  font-weight: bold;
}

.hover {
  cursor: pointer;
}

.popover-blue .arrow::after {
  border-bottom-color: #191e57 !important;
}

.hover-content {
  width: 800px;
}

.full-width {
  width: 100% !important;
}

.k-avatar-icon,
.k-avatar-initials {
  color: rgb(255, 255, 255);
  background-color: rgb(57, 64, 133);
}

.k-avatar-image > * {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.k-event-delete .k-icon {
  color: black;
  font-weight: bold;
  font-size: 20px !important;
}

.k-recurrence-editor .k-edit-label {
  display: none;
}


/* CSS File Changes Start */

.grid-child img {
  height: 154px;
  width: 155px;
  border-radius: 13px;
  margin-top: 80px;
}

.k-indicator-container {
  color: #ABAAB0;
  font-weight: bold;
}

table th {
  color: #ABAAB0;
}

td .k-reset {
  color: #191E57;
}

td .k-dropdown-button .k-button-icon {
  width: 100%;
}

.ng-star-inserted ul .k-state-focused {
  box-shadow: inset 0 0 0 2px rgb(0 0 0 / 0%) !important;
}

.k-popup {
  border-radius: 5px;
}

.modalClosebtn {
  float: right;
  margin-top: -51px;
  color: #00AEEF;
  height: 9%;
  margin-right: 0;
}

.patientmodaldivider {
  width: 98%;
}

.modalClosebtn .material-icons {
  font-size: 39px !important;
}

.mat-dialog-container {
  background-color: #FAFAFA;
}

.mat-error {
  color: #C83427 !important;
  margin-bottom: 15px !important;
}

.HEL_REG_12_16 {
  font-family: 'HelveticaNeueRegular', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 12PX;
  line-height: 16PX;
  letter-spacing: 0.15px;
}

.HEL_REG_14_16 {
  font-family: 'HelveticaNeueRegular', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 14PX;
  line-height: 16PX;
  letter-spacing: 0.10px;
}

.HEL_BOLD_14_16 {
  font-family: 'HelveticaNeueMedium', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 14PX;
  line-height: 16PX;
  letter-spacing: 0.15px;
}

.HEL_REG_16_22 {
  font-family: 'HelveticaNeueRegular', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22PX;
  letter-spacing: 0.15px;
}

.HEL_BOLD_16_22 {
  font-family: 'HelveticaNeueRegular', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 16PX;
  line-height: 22PX;
  letter-spacing: 0.15px;
}

.QUI_MED_20_28 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 20PX;
  line-height: 28PX;
  letter-spacing: -0.15px;
}

.QUI_BOLD_20_28 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 20PX;
  line-height: 28PX;
  letter-spacing: -0.15px;
}

.QUI_BOLD_24_32 {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 24PX;
  line-height: 32PX;
  letter-spacing: 0.10px;
}

.QUI_BOLD_18_32 {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 18PX;
  line-height: 32PX;
  letter-spacing: 0.10px;
  color: #191E57;
}

.QUI_BOLD_32_44 {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 32PX;
  line-height: 44PX;
  letter-spacing: 0.30px;
}

.QUI_SEM_46_54 {
  font-family: 'Quicksand';
  font-weight: 600;
  font-size: 46PX;
  line-height: 54PX;
  letter-spacing: 0.30px;
}

.tool-switch-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .res-table-desc-offerings {
    width: 240px !important;
  }
}


/* CSS File Changes End */

.mat-icon-button.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
  background: transparent !important;
}


/*----------------- new css Hardik -----------------*/


/*---------- button css-------------- */

.buttons-space {
  margin: 0 5px !important;
}

.button-custom-size {
  max-width: 75px !important;
  width: 75px !important;
  padding: unset !important;
}


/* primary-button */

.mat-raised-button[disabled] {
  color: #575757 !important;
  background-color: #f3f2f4 !important;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0 0 0 2px #f3f2f4 !important;
}

.mat-raised-button {
  background-color: #394085 !important;
  color: #ffffff !important;
  box-shadow: 0 2px #A18BD6 !important;
  min-height: 42px;
  font-weight: 700;
  font-family: 'HelveticaNeueBold' !important;
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  font-size: 16px;
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  background-color: #A18BD6 !important;
  box-shadow: 0 2px #394085 !important;
  color: #ffffff;
}

.mat-raised-button:active {
  background-color: #394085;
  color: #ffffff;
  box-shadow: 0 2px #A18BD6 !important;
  min-height: 42px;
}


/* secondary-button */

.mat-stroked-button {
  box-shadow: 0 2px #394085 !important;
  background-color: #F3F2F4 !important;
  color: #394085 !important;
  font-size: 14px;
  font-weight: 700 !important;
  border: 1px solid #394085 !important;
  min-height: 42px;
  font-family: 'HelveticaNeueBold' !important;
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  font-size: 16px;
}

.mat-stroked-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 2px 0 #394085 !important;
  background-color: #F3F3FE !important;
  color: #394085 !important;
  font-size: 14px;
  font-weight: 700 !important;
  border: 1px solid #F3F3FE !important;
}

.mat-stroked-button:active {
  box-shadow: 0 2px #394085 !important;
  background-color: #F3F2F4 !important;
  color: #394085 !important;
  font-size: 14px;
  font-weight: 700 !important;
  border: 1px solid #394085 !important;
  min-height: 42px;
}


/* sidebar */

.nav-list {
  padding-top: 0 !important;
}

.nav-list .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.sidebar-mat-form-field {
  position: relative !important;
}

.mat-select-panel-wrap .mat-select-panel {
  position: absolute;
  top: 46px !important;
}


/*---------- input field  ------------*/

.mat-form-field {
  width: 100% !important;
}

.mat-select-value,
input.mat-input-element {
  font-size: 16px !important;
  line-height: 19px !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #1C1B1B !important;
  margin-top: 0;
}

.mat-form-field-appearance-outline.mat-form-field-type-app-kendo-multi-select .mat-form-field-infix {
  padding: 8px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 18px 0 !important;
  border: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 2.1em !important;
  margin-top: 1px !important;
}

.mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  min-height: 54px;
}

.mat-error {
  font-size: 14px;
  font-weight: 500;
}

.mat-focused {
  color: #ABAAB0 !important;
  border-color: #394085 !important;
}

.mat-focused .mat-form-field-label {
  color: #394085 !important;
}

.mat-input-element {
  color: #1C1B1B !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #C83427 !important;
}

.ng-invalid .mat-form-field-outline-thick {
  color: #394085 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #C83427 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #394085 !important;
}

.mat-form-field-invalid {
  border-color: #C83427 !important;
  margin-bottom: 12px;
}

mat-form-field .mat-icon {
  display: none;
  color: #00AEEF !important;
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}

mat-form-field .icon-invalid {
  color: #00AEEF;
}

mat-form-field .icon-invalid {
  display: block;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  width: 16px !important;
  height: 16px !important;
}


/*---------- Simple Drop Down css-------------- */

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00AEEF !important;
}

.mat-select .mat-select-arrow-wrapper {
  padding-top: 13px;
}

.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 3px !important;
}


/* Droup Down Start */

.dropdown-wrapper {
  width: 100%;
  height: 54px;
}

.dropdown-wrapper .k-dropdown-wrap {
  background: #FFFFFF;
  padding: 15px 0;
  border-radius: 4px;
  border: 1px solid #DADADA;
}

.dropdown-wrapper .k-input {
  font-size: 16px;
  line-height: 19px;
  color: #939393;
}

.dx-dropdowneditor-input-wrapper {
  height: 54px !important;
}


/* Droup Down End */


/*---------- Drop Down mutiselect css-------------- */

kendo-multiselect {
  height: 54px;
  /* margin-bottom: 1.34375em; */
}

.k-multiselect.k-state-focused .k-multiselect-wrap {
  border: 2px solid #394085 !important;
}

.k-multiselect .k-multiselect-wrap {
  border-radius: 4px !important;
  border: 1px solid #ABAAB0 !important;
  overflow-y: scroll;
}

.k-multiselect .k-multiselect-wrap .k-searchbar .k-input {
  font-size: 16px;
  line-height: 19px;
  color: #939393;
  padding: 0;
  margin: 9px 0;
  padding-left: 12px;
}

.k-multiselect-wrap .k-reset .k-button {
  padding: 5px !important;
}


/*---------- Drop Down with search css-------------- */

app-add-new-dropdown .kendo-dropdown-size {
  height: 54px;
  font-size: inherit;
  width: 100%;
}

app-add-new-dropdown .k-dropdown {
  margin-bottom: 1.34375em;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap {
  background: white;
  padding: 0 0 1px 0 !important;
  border: 1px solid #ABAAB0;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap {
  border-radius: 4px;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap:hover,
app-add-new-dropdown .k-dropdowntree .k-dropdown-wrap:hover,
app-add-new-dropdown .k-dropdown .k-dropdown-wrap.k-state-hover,
app-add-new-dropdown .k-dropdowntree .k-dropdown-wrap.k-state-hover {
  background: white;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap:focus {
  border: 2px solid #394085;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap:hover {
  border: 2px solid #394085;
}

app-add-new-dropdown .k-dropdown .k-dropdown-wrap.k-state-focused {
  border: 2px solid #394085;
}

app-add-new-dropdown .k-dropdown .k-icon {
  color: #00AEEF !important;
}

kendo-card .k-icon {
  color: #00AEEF !important;
}


/*---------- text-area css-------------- */


/*---------- text-area end css-------------- */


/*-------- check-box customize -----------*/

.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
  margin-right: 0 !important;
}

mat-checkbox .mat-checkbox-label {
  padding-left: 0.5rem;
  padding-right: 1rem;
  /* line-height: inherit !important; */
}

.tn-p-checkbox mat-checkbox .mat-checkbox-label {
  line-height: inherit !important;
}

mat-checkbox {
  .mat-checkbox-frame {
    background-color: #ffffff;
    border-width: 2px;
    border-style: solid;
    border-color: #abaab0;
    border-radius: 4px;
  }

  &.ng-invalid.ng-touched .mat-checkbox-frame {
    border-color: #C83427;
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #A18BD6 !important;
  border-radius: 4px;
}


/*---------- Radio button css-------------- */

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #A18BD6 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #A18BD6 !important;
}

._mat-animation-noopable .mat-radio-outer-circle {
  height: 24px !important;
  width: 24px !important;
  background: #ffffff;
  border-color: #ABAAB0;
}

.mat-radio-inner-circle {
  height: 24px !important;
  width: 24px !important;
  left: -2px !important;
  top: -2px !important;
}

.mat-radio-label-content {
  //margin-top: 7px !important;
  //padding-left: 20px !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
}


/*---------- Radio button end css-------------- */


/* Toggle button Start */

mat-button-toggle-group {
  border-bottom: 3px solid #394085;
  border-radius: 6px !important;
  background: #394085;
  min-width: 156px;
  height: 34px;
  max-width: 156px;
}

mat-button-toggle-group .mat-button-toggle-group {
  box-shadow: 0 3px 0 #394085;
}

mat-button-toggle-group .mat-button-toggle {
  border: 2px solid #394085;
  min-width: 82px;
  color: #394085 !important;
  background: #f3f2f4;
  height: 32px;
  font-family: 'HelveticaNeueBold';
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  font-size: 16px;
  font-weight: 700;
}

mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked {
  border: 2px solid #A18BD6;
  background-color: #A18BD6;
  color: white !important;
  min-width: 74px;
  font-family: 'HelveticaNeueBold';
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  font-size: 16px;
  font-weight: 700;
}

mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
  color: #394085;
  line-height: 28px;
  padding: unset !important;
}

mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content {
  color: #ffffff;
  text-shadow: 0 2px #394085;
}

mat-button-toggle-group #mat-button-toggle-11.mat-button-toggle {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

mat-button-toggle-group #mat-button-toggle-12.mat-button-toggle {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

mat-button-toggle mat-icon {
  margin-right: 0rem !important;
}

mat-icon {
  overflow: unset !important;
}

/* Toggle button End */


/* Toggle switch Start */

.k-switch-on .k-switch-label-on {
  text-shadow: 0 2px #394085;
}

.k-switch-off .k-switch-label-off {
  text-shadow: 0 2px #ffffff;
}

.k-switch-off .k-switch-thumb-wrap .k-switch-thumb,
.k-switch-off:hover .k-switch-thumb-wrap .k-switch-thumb {
  background-color: #A18BD6 !important;
  box-shadow: 0 2px #394085 !important;
}

.k-switch-on .k-switch-thumb-wrap .k-switch-thumb,
.k-switch-on:hover .k-switch-thumb-wrap .k-switch-thumb {
  background-color: #ffffff;
  box-shadow: 0 2px #cfd2d6;
}

kendo-switch.k-switch {
  width: 118px !important;
  height: 32px !important;
  overflow: visible !important;
  min-width: 118px !important;
}

kendo-switch.k-switch .k-switch-track {
  height: 32px !important;
}

.k-switch-thumb {
  width: 3em !important;
  height: 3em !important;
}

.k-switch-label-off,
.k-switch-label-on {
  text-align: center !important;
  text-transform: capitalize !important;
}

.k-switch-on .k-switch-track,
.k-switch-on:hover .k-switch-track,
.k-switch-on.k-state-hover .k-switch-track {
  background-color: #A18BD6 !important;
  box-shadow: 0 3px #394085 !important;
  padding-bottom: 2px;
  border: 1px solid #A18BD6;
  outline: 0;
}

.k-switch-off .k-switch-track,
.k-switch-off:hover .k-switch-track,
.k-switch-off.k-state-hover .k-switch-track {
  background-color: #f3f2f4 !important;
  box-shadow: 0 2px #cfd2d6 !important;
  padding-bottom: 2px;
  border: 1px solid #cfd2d6;
  outline: 0;
}

.k-switch-label-on,
.k-switch-label-off {
  text-transform: capitalize !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px !important;
  font-family: 'HelveticaNeueBold';
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  letter-spacing: 0.15px;
}

.k-switch-label-off {
  color: #394085;
}


/* Toggle switch End */


/* tab bar Start */

.mat-tab-group.mat-primary .mat-ink-bar {
  Background: #A18BD6 !important;
  height: 5px !important;
  background-color: #A18BD6 !important;
}

.mat-tab-label .mat-tab-label-content {
  color: #394085 !important;
}

.mat-tab-label:hover {
  Background: #394085;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #FFFFFF !important;
}

.mat-tab-label:hover .mat-tab-label-content {
  color: #FFFFFF !important;
}

.mat-tab-label:active {
  Background: #A18BD6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #FFFFFF !important;
}

.mat-tab-label:active .mat-tab-label-content {
  color: #FFFFFF !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-label-active .mat-tab-label-content {
  color: #1C1B1B !important;
  font-weight: bold;
}


/* tab bar End */


/* accordion start */

.mat-expansion-panel {
  margin-bottom: 15px !important;
}

.mat-expansion-panel-header:hover .mat-expansion-panel-header-title,
.mat-expansion-panel-header:hover .mat-expansion-panel-header-description {
  color: #00AEEF !important;
}

.mat-expanded .mat-expansion-panel-header {
  height: 48px !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:focus:not([aria-disabled=true]) {
  background: #F3F3FE !important;
}

.mat-expansion-panel .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: #ffffff !important;
}

.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  color: #1C1B1B;
  font-family: 'HelveticaNeueBold';
  /* font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif; */
  letter-spacing: 0.15px;
}

// kendo-avtar
.k-avatar-solid-primary {
  border-color: #394085;
  color: white;
  background-color: #394085;
}


/* accordion end */


/* hyperlink start*/

a {
  text-decoration: none;
  color: #00AEEF !important;
}

a:hover {
  color: #00AEEF;
  text-decoration: underline;
}

.k-grid .k-grid-column-menu,
.k-grid .k-grid-filter-menu,
.k-grid .k-header-column-menu,
.k-grid .k-hierarchy-cell .k-icon {
  color: #656565 !important;
}

kendo-pager a {
  color: #656565 !important;
}


/* hyperlink end*/


/* calender */

.k-calendar-navigation {
  display: none !important;
}


/* calender end*/

.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  top: 17px !important;
}


/* Scroll Design Start */

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar:hover {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(88, 87, 87);
}

div:hover::-webkit-scrollbar-corner {
  width: 5px;
}


/* Scroll Design End */


/* save & cancel button start*/

.footer-btn-customize {
  min-width: 75px !important;
  //width: 75px !important;
}


/* save & cancel button end*/


/* delete button */

.mat-stroked-button-delete {
  background: #D41515 !important;
  box-shadow: 0 2px #394085 !important;
  width: 90px !important;
  color: #ffffff !important;
  border: 1px solid #394085 !important;
  font-size: 14px;
}

.mat-raised-button.custom-delete-btn:not([disabled]):active:not([class*=mat-elevation-z]) {
  background-color: #ffdfdf !important;
  box-shadow: 0 2px #394085 !important;
  border: 1px solid #ffdfdf;
  color: #394085 !important;
}

.mat-stroked-button.custom-delete-btn:not([disabled]):active:not([class*=mat-elevation-z]) {
  background-color: #ffdfdf !important;
  box-shadow: 0 2px #394085 !important;
  border: 1px solid #ffdfdf;
  color: #394085 !important;
}


/* delete button end*/


/* add item-btn-start  */

.add-item-btn {
  position: relative;
  padding-top: 8px !important;
}

.add-item-btn .mat-raised-button {
  height: 54px !important;
}


/* add item-btn -end*/


/* kendo-date-picker  */

.field-custom-label {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #1C1B1B !important;
}

.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-timepicker {
  font-size: 16px !important;
  width: 100% !important;
  border-radius: 4px !important;
}

.k-dateinput .k-dateinput-wrap {
  padding: 11px 0 !important;
  /* height: 54px; */
}

.k-picker-wrap {
  border-radius: 4px !important;
  border-width: 2px !important;
}

.k-dateinput.k-state-invalid .k-dateinput-wrap,
.k-dateinput.k-state-invalid .k-picker-wrap,
.k-dateinput.ng-invalid.ng-touched .k-dateinput-wrap,
.k-dateinput.ng-invalid.ng-touched .k-picker-wrap,
.k-dateinput.ng-invalid.ng-dirty .k-dateinput-wrap,
.k-dateinput.ng-invalid.ng-dirty .k-picker-wrap,
.k-datepicker.k-state-invalid .k-dateinput-wrap,
.k-datepicker.k-state-invalid .k-picker-wrap,
.k-datepicker.ng-invalid.ng-touched .k-dateinput-wrap,
.k-datepicker.ng-invalid.ng-touched .k-picker-wrap,
.k-datepicker.ng-invalid.ng-dirty .k-dateinput-wrap,
.k-datepicker.ng-invalid.ng-dirty .k-picker-wrap,
.k-datetimepicker.k-state-invalid .k-dateinput-wrap,
.k-datetimepicker.k-state-invalid .k-picker-wrap,
.k-datetimepicker.ng-invalid.ng-touched .k-dateinput-wrap,
.k-datetimepicker.ng-invalid.ng-touched .k-picker-wrap,
.k-datetimepicker.ng-invalid.ng-dirty .k-dateinput-wrap,
.k-datetimepicker.ng-invalid.ng-dirty .k-picker-wrap,
.k-timepicker.k-state-invalid .k-dateinput-wrap,
.k-timepicker.k-state-invalid .k-picker-wrap,
.k-timepicker.ng-invalid.ng-touched .k-dateinput-wrap,
.k-timepicker.ng-invalid.ng-touched .k-picker-wrap,
.k-timepicker.ng-invalid.ng-dirty .k-dateinput-wrap,
.k-timepicker.ng-invalid.ng-dirty .k-picker-wrap {
  border-color: #c83427 !important;
  border-radius: 4px !important;
  border-width: 2px !important;
}

.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-datepicker .k-dateinput-wrap.k-state-focused,
.k-datepicker .k-dateinput-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-dateinput-wrap.k-state-focused,
.k-timepicker .k-dateinput-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-dateinput-wrap.k-state-focused,
.k-datetimepicker .k-dateinput-wrap.k-state-active,
.k-dateinput .k-picker-wrap.k-state-focused,
.k-dateinput .k-picker-wrap.k-state-active,
.k-dateinput .k-dateinput-wrap.k-state-focused,
.k-dateinput .k-dateinput-wrap.k-state-active {
  border-color: #394085 !important;
  box-shadow: none !important;
  color: #939393 !important;
}

.k-datepicker .k-picker-wrap:hover,
.k-datepicker .k-picker-wrap.k-state-hover,
.k-datepicker .k-dateinput-wrap:hover,
.k-datepicker .k-dateinput-wrap.k-state-hover,
.k-timepicker .k-picker-wrap:hover,
.k-timepicker .k-picker-wrap.k-state-hover,
.k-timepicker .k-dateinput-wrap:hover,
.k-timepicker .k-dateinput-wrap.k-state-hover,
.k-datetimepicker .k-picker-wrap:hover,
.k-datetimepicker .k-picker-wrap.k-state-hover,
.k-datetimepicker .k-dateinput-wrap:hover,
.k-datetimepicker .k-dateinput-wrap.k-state-hover,
.k-dateinput .k-picker-wrap:hover,
.k-dateinput .k-picker-wrap.k-state-hover,
.k-dateinput .k-dateinput-wrap:hover,
.k-dateinput .k-dateinput-wrap.k-state-hover {
  border-color: #394085 !important;
  border-radius: 4px !important;
  border-width: 2px !important;
}

.k-datepicker .k-picker-wrap,
.k-datepicker .k-dateinput-wrap,
.k-timepicker .k-picker-wrap,
.k-timepicker .k-dateinput-wrap,
.k-datetimepicker .k-picker-wrap,
.k-datetimepicker .k-dateinput-wrap,
.k-dateinput .k-picker-wrap,
.k-dateinput .k-dateinput-wrap {
  border-color: #ABAAB0 !important;
  color: #939393 !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border-width: 1px !important;
}

.k-datepicker .k-select {
  color: #394085 !important;
  background-color: #f6f6f600 !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#f1f1f1)) !important;
  background-image: linear-gradient(#f6f6f600, #f1f1f100) !important;
}

.k-dropdown.k-state-invalid > .k-dropdown-wrap,
.k-state-invalid.k-dropdowntree > .k-dropdown-wrap,
.k-dropdown.ng-invalid.ng-touched > .k-dropdown-wrap,
.ng-invalid.ng-touched.k-dropdowntree > .k-dropdown-wrap,
.k-dropdown.ng-invalid.ng-dirty > .k-dropdown-wrap,
.ng-invalid.ng-dirty.k-dropdowntree > .k-dropdown-wrap {
  border-color: #c83427 !important;
}

.k-dropdown .k-input-inner,
.k-dropdowntree .k-dropdown-wrap {
  border-radius: 4px !important;
  border-width: 1px !important;
  align-items: center;
  background-image: none !important;
  background-color: transparent !important;
}


/* kendo-date-picker end */


/* alert */

#alert {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
}


/* alert end */


/* time-picker */

#time-picker-wrapper {
  border-radius: 4px !important;
  border: 1px solid #ABAAB0 !important;
}

#time-picker-wrapper.active {
  border: 2px solid #394085 !important;
}

#time-picker-wrapper:hover {
  border: 2px solid #394085 !important;
}

html {
  --lumo-space-xs: 0 !important;
  --lumo-icon-size-m: 24px !important;
  --lumo-shade-70pct: #abaab0 !important;
  --lumo-contrast-10pct: transparent !important;
  --lumo-size-m: 54px !important;
  --lumo-contrast-60pct: #394085 !important;
}


/* time-picker end*/

.c-tool-tab-group .mat-tab-labels .mat-tab-label {
  min-width: 170px !important;
  padding: unset;
}

.c-tool-tab-group .mat-tab-labels .mat-tab-label-active {
  border-bottom: 4px solid #a18bd6 !important;
}

.vertical-tabbar .mat-tab-label.mat-tab-label-active::before {
  content: '';
  background: #a18bd6;
  height: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (min-width: 1440px) {
  .vertical-tabbar .mat-tab-label-container {
    background-color: #f6f6f6;
  }
}

.c-kendo-dropdownlist {
  width: 100%;
  height: 54px;
}


/* hardik */


/* new css start by Dhaval */

.padding-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-left {
  padding-left: 0 !important;
}

.padding-right {
  padding-right: 0 !important;
}

.mr-left-right {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mr-left {
  margin-left: 0 !important;
}

.mr-right {
  margin-right: 0 !important;
}

.custom-fit-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-delete-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.custom-delete-modal .mat-icon-button {
  width: unset;
  height: unset;
}

.custom-delete-modal .mat-icon {
  display: block !important;
  color: #00AEEF !important;
  margin: 0 !important;
  font-size: 30px !important;
  line-height: 1 !important;
  height: unset;
  width: unset;
}

.custom-dlt-content {
  padding-top: 10px;
  padding-bottom: 70px;
}


/* kendo range selecter start*/

//.k-slider-buttons .k-slider-items {
//  margin-left: 10% !important;
//  width: 100% !important;
//}
//
//.k-slider-horizontal .k-slider-buttons .k-slider-track {
//  left: 0 !important;
//}
//
//.k-slider-horizontal .k-last .k-label {
//  right: 0 !important;
//}
//
//.k-slider-horizontal .k-first .k-label {
//  left: -80%;
//}

.k-slider-horizontal .k-slider-items {
  display: flex;
  width: 100%;

  .k-tick {
    margin-left: 0;
  }
}


/* kendo range selecter end*/


/* new css end by Dhaval */


/* tooltip */


/* Dhaval css start */

.mat-tooltip {
  color: white !important;
  background: black !important;
}

.custom-referral-grid thead tr th {
  background: #F5F5F5;
  color: #000000 !important;
  font-size: 16px !important;
  padding: 5px !important;
}

.dx-state-active {
  background-color: #b195db !important;
  opacity: 20% !important;
  color: #000000 !important;
}

.custom-patient-tabs .mat-tab-label-content {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
}

.selected-dropdown-wrapper .k-icon {
  color: #00AEEF;
}

.mat-tab-group {
  font-family: "HelveticaNeueMedium" !important;
}


/* Dhaval css end*/

/* Added by KHUSH */
.no-border-bg-btn {
  background: none;
  border: none;
}

.no-border-bg-btn:disabled {
  background: none;
}

.disable-label {
  color: #979797 !important;
}

.disable-label.mat-input-element {
  color: #979797 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.required-label-top-container {
  margin-top: -12px;
  text-align: right;
}

.required-label-top {
  font-size: 13px;
  line-height: 16px;
  color: #dc3545;
  font-family: "HelveticaNeueMedium";
  margin: 0;
}

.mat-select-disabled .mat-select-value,
.mat-form-field-disabled .mat-form-field-label,
.mat-form-field-disabled input {
  color: #979797 !important;
}

.mat-form-field-disabled.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #a2a2a2;
  background: transparent;
}

.mat-select-disabled.remove-arrow .mat-select-arrow-wrapper {
  opacity: 0;
}

.text-black {
  color: #000000 !important;
}

/* Chrome, Safari, Edge, Opera */
input.remove-spin-btn[type=number]::-webkit-outer-spin-button,
input.remove-spin-btn[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.remove-spin-btn[type=number] {
  -moz-appearance: textfield;
}

.dx-calendar-cell.dx-calendar-empty-cell {
  background: none;
}

/* CSS FOR APP CARD WITH RIGHT CONTROL */
.app-card {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid white;
  border-radius: 4px;
  letter-spacing: 0.15px;
  color: #000000;
  font-size: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  height: 95px;
  padding-right: 0;
}

.app-card-title {
  font-family: "HelveticaNeueRegular", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22.5px;
  color: #1c1b1b;
}

.app-card-content-wrapper {
  flex: 1 1;
}

.app-card-content {
  margin-top: 5px;
  font-family: "HelveticaNeueRegular", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  word-break: break-word;
  text-align: justify;
}

.app-card-control {
  width: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*position: absolute;*/
  /*right: 0;*/
}

.margin-20px {
  margin-top: 20px;
}

/* SMALL KENDO SWITCH */
kendo-switch.small.k-switch {
  width: 56px !important;
  min-width: 56px !important;
  height: 25px !important;
  overflow: visible !important;
}

.k-switch-md .k-switch-thumb {
  width: 24px;
  height: 24px;
}

kendo-switch.small.k-switch .k-switch-track {
  width: 56px !important;
  min-width: 56px !important;
  height: 25px !important;
}

kendo-switch.small.k-switch-off .k-switch-track,
kendo-switch.small.k-switch-off:hover .k-switch-track,
kendo-switch.small.k-switch-off.k-state-hover .k-switch-track {
  background-color: #f3f2f4 !important;
  box-shadow: none !important;
  border: 1px solid #cfd2d6;
}

kendo-switch.small .k-switch-thumb {
  width: 21px !important;
  height: 21px !important;
}

kendo-switch.small.k-switch-off .k-switch-thumb-wrap {
  left: 12px;
}

kendo-switch.small.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 11px);
}

kendo-switch.small .k-switch-label-off {
  right: 10px !important;
  width: fit-content;
}

kendo-switch.small .k-switch-label-off,
kendo-switch.small .k-switch-label-on {
  font-size: 10px !important;
}

.mat-form-field-type-app-appt-dropdown-with-new .mat-form-field-infix {
  height: 51px;
  padding: 13px 0 !important;
  margin-right: 0;
}

/* Chrome, Safari, Edge, Opera */
input.remove-arrow.ngx-rrule::-webkit-outer-spin-button,
input.remove-arrow.ngx-rrule::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.remove-arrow.ngx-rrule[type=number] {
  -moz-appearance: textfield;
}

.mat-checkbox-background, .mat-checkbox-frame {
  border-radius: 4px !important;
}

.ft-size-10 {
  font-size: 10px !important;
}

.app-popup-footer {
  height: 82px;
  border-top: 1px solid #ABAAB0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.action-btn {
  height: 42px;
}

.app-popup .dx-popup-normal {
  background: #FAFAFA !important;
  border-radius: 10px !important;
  border: 1px solid #DBDBDB !important;
}

.app-popup .dx-popup-arrow::after {
  background: #FAFAFA !important;
}

.app-popup .dx-popup-content {
  padding: 0 !important;
}

.app-popup-list-button:hover, .app-popup-list-button.selected {
  background: rgba(177, 149, 219, 0.2);
  color: rgba(57, 64, 133, 1);
}

.app-popup-list-button {
  background: none;
  border: none;
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  font-size: 14px;
  font-weight: 500;
}

.app-popup-list-button:last-child {
  border: none;
}

.k-dialog-wrapper {
  z-index: 1000;
}

.search-patient kendo-autocomplete {
  position: absolute;
  border: none;
  box-shadow: none;
  margin-top: -7px;
}

.search-patient kendo-autocomplete.k-state-focused {
  box-shadow: none !important;
}

.search-patient kendo-autocomplete .k-clear-value {
  height: 100%;
}

.k-treeview-item .k-state-selected {
  background: none !important;
}

/* Grid Action Popup Style*/
.grid-action-popup.dx-popup-normal {
  background: #FAFAFA !important;
  border-radius: 10px !important;
  border: 1px solid #DBDBDB !important;
}

.grid-action-popup .dx-popup-arrow::after {
  background: #FAFAFA !important;
}

.grid-action-popup .dx-popup-content {
  padding: 0 !important;
}

.grid-action-popup-button:hover, .grid-action-popup-button.selected {
  background: rgba(177, 149, 219, 0.2);
  color: rgba(57, 64, 133, 1);
}

.grid-action-popup-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  font-size: 14px;
  font-weight: 500;
}

.grid-action-popup-button:last-child {
  border: none;
}

/* End - Grid Action Popup Style*/

.mat-file-select-ctrl {
  opacity: 0;
  width: 100%;
}

//.k-grid-header {
//  padding: 0 !important;
//}

.remove-locked-col-border.k-grid {
  .k-grid-header-locked,
  .k-grid-content-locked {
    border-color: rgba(0, 0, 0, 0.08) !important;

    th, td {
      border-color: rgba(0, 0, 0, 0.08) !important;
    }

    td {
      border-right-color: rgba(0, 0, 0, 0.08) !important;
    }
  }

  #k-treelist0-r0c1 {
    height: 41px !important;
  }

}

textarea {
  resize: none !important;
}

.tab-title {
  height: 42px;
  border-bottom: 5px solid #A18BD6;
  min-width: 160px;
  font-size: 16px;
}

.cke_editable p {
  margin: 0;
}

.mat-form-field-type-app-dropdown-search.remove-mat-form-field-infix-padding {
  .mat-form-field-infix {
    padding: 13px 0 !important;

    .app-auto-complete {
      height: unset;
    }
  }
}

.mat-form-field-type-app-dropdown-tree-view.remove-mat-form-field-infix-padding {
  .mat-form-field-infix {

    .app-kdt {
      height: unset;
    }
  }
}

.mat-tab-group.small-tabs {
  .mat-tab-labels {
    .mat-tab-label {
      min-width: fit-content !important;
      flex: 1;
      height: 35px;
    }
  }
}

.mat-menu-panel.btn-menu {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.text-break-space {
  white-space: break-spaces;
}

.cke_editable {
  line-height: 1 !important;
}

button:disabled mat-icon {
  color: rgba(28, 27, 27, 0.3);
  pointer-events: none !important;
}

.hide-cke-top, .hide-cke-bottom {
  display: none !important;
}

.hide-cke {
  &-header {
    .cke_top {
      @extend .hide-cke-top;
    }

    &-footer {
      .cke_top {
        @extend .hide-cke-top;
      }

      .cke_bottom {
        @extend .hide-cke-bottom;
      }
    }
  }

  &-footer .cke_bottom {
    @extend .hide-cke-bottom;
  }
}

.mat-form-field-h-100 {
  height: 100%;

  .mat-form-field-infix, .mat-form-field-flex, .mat-form-field-wrapper {
    height: 100%;
  }
}

.mat-form-field.rm-bottom-padding .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.k-icon.k-svg-i-caret-alt-down.k-treelist-toggle, kendo-dropdownlist .k-icon.k-svg-i-caret-alt-down {
  color: #00aeef;
}

.k-multiselect-popup label[for="selectAll"] .k-checkbox {
  opacity: 0;
}

/* END KHUSH CSS */
