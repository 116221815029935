.dx-scheduler-navigator-calendar {
  &.dx-calendar {
    width: 239px;
    min-width: 239px;
    height: 226px;
    min-height: 226px;
    background: transparent;
  }

  &.M-F {
    .dx-calendar-body {
      tbody {
        tr {
          &:has(td.dx-calendar-selected-date) {
            td {
              background: #307afa;

              &:nth-last-child(2), &:nth-last-child(1) {
                background: transparent;

                span {
                  color: #484848;
                }
              }

              span {
                color: #fff;
              }
            }
          }

          &:hover td {
            background: rgba(48, 122, 250, 0.5);
            color: #fff;

            &:nth-last-child(2), &:nth-last-child(1) {
              background: transparent;
              color: #484848;
            }
          }
        }
      }
    }
  }

  &.Week {
    .dx-calendar-body {
      tbody {
        tr {
          &:has(td.dx-calendar-selected-date) {
            td {
              background: #307afa;

              span {
                color: #fff;
              }
            }
          }

          &:hover td {
            background: rgba(48, 122, 250, 0.5);
            color: #fff;
          }
        }
      }
    }
  }

  .dx-calendar-body {
    top: 36px;

    tbody {
      tr {
        height: 25px !important;

        &:hover {
          background-color: transparent !important;
        }
      }
    }

    thead {
      tr {
        height: 25px !important;

        th {
          box-shadow: none !important;
          border: none !important;
        }
      }
    }
  }

  .dx-calendar-navigator {
    width: 100%;
    height: 29px;
    max-height: 29px;
    background: #f6f6f6;
  }

  .dx-calendar-cell {
    &:not(.dx-calendar-empty-cell) {
      color: #484848;
    }

    padding: 0 !important;
    border: none;

    &:hover {
      background: none !important;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .dx-calendar-cell.dx-state-hover span {
    background: none;
  }

  .dx-calendar-cell {
    width: 100%;
    height: 24px;
  }

  .dx-calendar-cell {
    &:hover span{
      background: rgba(48, 122, 250, 0.25);
      border-radius: 100%;
    }

    span {
      font-size: 12px;
      width: 24px;
      height: 24px;
      box-shadow: none !important;

      &:hover {
        background: rgba(48, 122, 250, 0.25);
        border-radius: 100%;
      }
    }
  }

  .dx-calendar-cell.dx-calendar-selected-date,
  .dx-calendar-cell.dx-calendar-today,
  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
    span {
      background: #307afa;
      border-radius: 100%;
      color: #fff;
    }

    box-shadow: none;
  }

  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
    box-shadow: none;
  }

  .dx-calendar-navigator a {
    background: none;
    border: none;
    border-radius: 0;

    &:nth-child(2) {
      text-transform: capitalize;

      .dx-button-content {
        .dx-button-text {
          color: #484848 !important;
        }
      }
    }
  }
}
